<script setup lang="ts">
import { Blog } from '@/Pages/the-home/interfaces/Blog'
import { FeaturedCity } from '@/Components/featured-cities-slider/interfaces/FeaturedCity'
import DefaultLayout from '@/Layouts/DefaultLayout.vue'
import TheHeader from '@/Components/TheHeader.vue'
import TheHomeHero from '@/Pages/the-home/components/TheHomeHero.vue'
import TheHomeCompare from '@/Pages/the-home/components/TheHomeCompare.vue'
import TheHomeAdvantages from '@/Pages/the-home/components/TheHomeAdvantages.vue'
import TheHomeTestimonials from '@/Pages/the-home/components/TheHomeTestimonials.vue'
import TheHomeBlog from '@/Pages/the-home/components/TheHomeBlog.vue'
import TheHomeFeaturedCities from '@/Pages/the-home/components/TheHomeFeaturedCities.vue'
import TheHomeMoreFeaturedCities from '@/Pages/the-home/components/TheHomeMoreFeaturedCities.vue'

import { onMounted } from 'vue'
import { usePage } from '@inertiajs/vue3'
import {
    toggleIsUserLogged
} from '@/Composables/authorization'
import SearchBox from '@/Components/search-box/SearchBox.vue'
import { useCurrencyWatcher } from '@/Composables/use-currency'
import { router } from '@inertiajs/vue3'

defineProps<{
    featuredCities: FeaturedCity[]
    blogs: Blog[]
}>()

const page = usePage()

onMounted(() => {
    if (page.props.user) {
        toggleIsUserLogged(true)
    } else {
        toggleIsUserLogged(false)
    }
})

useCurrencyWatcher((newCurrency) => {
    // partial reload featured cities
    router.reload({
        only: ['featuredCities', 'activeCurrency'],
        data: {
            currency: newCurrency
        },
        method: 'post'
    })
})

</script>

<template>
    <DefaultLayout>
        <template #header>
            <TheHeader />
        </template>

        <TheHomeHero />

        <SearchBox class="mx-3 mt-3 d-sm-none" />

        <TheHomeCompare />

        <TheHomeAdvantages />

        <TheHomeFeaturedCities :cities="featuredCities" />

        <TheHomeTestimonials />

        <TheHomeBlog :blogs="blogs" />

        <TheHomeMoreFeaturedCities />
    </DefaultLayout>
</template>

<style lang="scss" scoped>
:deep(.header-line) {
    height: 0;
}
</style>